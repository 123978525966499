<template>
  <div class="auth-content">
    <div class="auth window-width row justify-center items-center">
      <FormComponent />
    </div>
  </div>
</template>

<script setup>
import FormComponent from "./components/FormComponent.vue";
</script>

<style lang="scss" scoped>
.auth {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
.desktop {
  .auth {
    background: #f0f0f0;
    .q-card {
      width: 100%;
      max-width: 400px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    }
  }
}
.mobile {
  .auth {
    align-items: normal;
    background: #f0f0f0;
    .q-card {
      width: 100%;
      box-shadow: none;
      margin: 0;
    }
  }
}
:root {
  --q-negative: #f16192;
}

html {
  overflow-x: hidden;
}
</style>
